/**
 * Hexio App Engine Core library.
 *
 * @package hae-lib-core
 * @copyright 2022 Hexio a.s. <contact@hexio.io> (hexio.io)
 * @license Commercial
 *
 * See LICENSE file distributed with this source code for more information.
 */

import { ISchemaFlowNodeSpec } from "@hexio_io/hae-lib-blueprint";
import {
	BlueprintActionNode,
	BlueprintConditionNode,
	BlueprintStartNode,
	BlueprintTransformNode,
	BlueprintErrorNode,
	BlueprintIntegrationNode,
	BlueprintLoginNode,
	BlueprintLogoutNode,
	BlueprintMapNode,
	BlueprintOutputNode,
	BlueprintRedirectNode,
	BlueprintRequestNode,
	BlueprintResponseNode,
	BlueprintSetSessionNode,
	BlueprintViewToHtmlNode,
	BlueprintTimeoutNode,
	BlueprintVariableNode,
	BlueprintReduceNode as BlueprintFlowNodeTypeReduce,
	NODE_TYPES,
	BlueprintReduceNode,
	BlueprintActionNode_v2,
	BlueprintWhileNode,
	BlueprintFileCreateNode,
	BlueprintFileReadNode
} from "./nodes";
import { BlueprintLogNode } from "./nodes/BlueprintNodeLog";

export const BlueprintNodeTypes = {
	[NODE_TYPES.ACTION]: BlueprintActionNode,
	[NODE_TYPES.ACTION_V2]: BlueprintActionNode_v2,
	[NODE_TYPES.CONDITION]: BlueprintConditionNode,
	[NODE_TYPES.THROW]: BlueprintErrorNode,
	[NODE_TYPES.INTEGRATION]: BlueprintIntegrationNode,
	[NODE_TYPES.LOGIN]: BlueprintLoginNode,
	[NODE_TYPES.LOGOUT]: BlueprintLogoutNode,
	[NODE_TYPES.MAP]: BlueprintMapNode,
	[NODE_TYPES.RETURN]: BlueprintOutputNode,
	[NODE_TYPES.REDIRECT]: BlueprintRedirectNode,
	[NODE_TYPES.REDUCE]: BlueprintFlowNodeTypeReduce,
	[NODE_TYPES.RESPONSE]: BlueprintResponseNode,
	[NODE_TYPES.REQUEST]: BlueprintRequestNode,
	[NODE_TYPES.SET_SESSION]: BlueprintSetSessionNode,
	[NODE_TYPES.START]: BlueprintStartNode,
	[NODE_TYPES.TIMEOUT]: BlueprintTimeoutNode,
	[NODE_TYPES.TRANSFORM]: BlueprintTransformNode,
	[NODE_TYPES.VIEW_TO_HTML]: BlueprintViewToHtmlNode,
	[NODE_TYPES.VAR]: BlueprintVariableNode,
	[NODE_TYPES.WHILE]: BlueprintWhileNode,
	[NODE_TYPES.FILE_CREATE]: BlueprintFileCreateNode,
	[NODE_TYPES.FILE_READ]: BlueprintFileReadNode,
	[NODE_TYPES.LOG]: BlueprintLogNode
};

export type TBlueprintNodeTypes = typeof BlueprintNodeTypes;

export const BlueprintActionNodeTypes = {
	[NODE_TYPES.START]: BlueprintStartNode,
	[NODE_TYPES.INTEGRATION]: BlueprintIntegrationNode,
	[NODE_TYPES.ACTION]: BlueprintActionNode,
	[NODE_TYPES.ACTION_V2]: BlueprintActionNode_v2,
	[NODE_TYPES.TRANSFORM]: BlueprintTransformNode,
	[NODE_TYPES.CONDITION]: BlueprintConditionNode,
	[NODE_TYPES.RETURN]: BlueprintOutputNode,
	[NODE_TYPES.THROW]: BlueprintErrorNode,
	[NODE_TYPES.VIEW_TO_HTML]: BlueprintViewToHtmlNode,
	[NODE_TYPES.LOGIN]: BlueprintLoginNode,
	[NODE_TYPES.LOGOUT]: BlueprintLogoutNode,
	[NODE_TYPES.SET_SESSION]: BlueprintSetSessionNode,
	[NODE_TYPES.MAP]: BlueprintMapNode,
	[NODE_TYPES.REDUCE]: BlueprintReduceNode,
	[NODE_TYPES.VAR]: BlueprintVariableNode,
	[NODE_TYPES.TIMEOUT]: BlueprintTimeoutNode,
	[NODE_TYPES.WHILE]: BlueprintWhileNode,
	[NODE_TYPES.FILE_CREATE]: BlueprintFileCreateNode,
	[NODE_TYPES.FILE_READ]: BlueprintFileReadNode,
	[NODE_TYPES.LOG]: BlueprintLogNode
};

//export type TBlueprintActionNodeTypes = typeof BlueprintActionNodeTypes;
export type TBlueprintActionNodeTypes = Pick<
	TBlueprintNodeTypes,
	| NODE_TYPES.START
	| NODE_TYPES.INTEGRATION
	| NODE_TYPES.ACTION
	| NODE_TYPES.ACTION_V2
	| NODE_TYPES.TRANSFORM
	| NODE_TYPES.CONDITION
	| NODE_TYPES.RETURN
	| NODE_TYPES.THROW
	| NODE_TYPES.VIEW_TO_HTML
	| NODE_TYPES.LOGIN
	| NODE_TYPES.LOGOUT
	| NODE_TYPES.SET_SESSION
	| NODE_TYPES.MAP
	| NODE_TYPES.REDUCE
	| NODE_TYPES.VAR
	| NODE_TYPES.TIMEOUT
	| NODE_TYPES.WHILE
	| NODE_TYPES.FILE_CREATE
	| NODE_TYPES.FILE_READ
	| NODE_TYPES.LOG
>;

export const BlueprintEndpointFlowNodeTypes = {
	[NODE_TYPES.REQUEST]: BlueprintRequestNode,
	[NODE_TYPES.RESPONSE]: BlueprintResponseNode,
	[NODE_TYPES.REDIRECT]: BlueprintRedirectNode,
	[NODE_TYPES.ACTION]: BlueprintActionNode,
	[NODE_TYPES.ACTION_V2]: BlueprintActionNode_v2,
	[NODE_TYPES.CONDITION]: BlueprintConditionNode,
	[NODE_TYPES.TRANSFORM]: BlueprintTransformNode,
	[NODE_TYPES.LOG]: BlueprintLogNode
};

//export type TBlueprintEndpointNodeTypes = typeof BlueprintEndpointFlowNodeTypes;
export type TBlueprintEndpointNodeTypes = Pick<
	TBlueprintNodeTypes,
	| NODE_TYPES.REQUEST
	| NODE_TYPES.RESPONSE
	| NODE_TYPES.REDIRECT
	| NODE_TYPES.ACTION
	| NODE_TYPES.ACTION_V2
	| NODE_TYPES.CONDITION
	| NODE_TYPES.TRANSFORM
	| NODE_TYPES.LOG
>;

export type TActionNodesSpec = ISchemaFlowNodeSpec<TBlueprintActionNodeTypes>;
export type TEndpointNodesSpec = ISchemaFlowNodeSpec<TBlueprintEndpointNodeTypes>;
export type TAllNodesSpec = ISchemaFlowNodeSpec<TBlueprintNodeTypes>;
